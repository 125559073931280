﻿@import "./_constants.scss";

body {
  h1 {
    /*color: $topbar-color;*/
    text-align: center;
  }

  position: fixed; //disabled iPad overscrolling
  width: 100vw;
  font-family: Arial, Helvetica, sans-serif;
}

.btn-warning {
  color: #fff;
  background-color: orange;
  border-color: #eea236;
}

.btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}

.btn-warning:disabled {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
  padding: 6px 12px;
  border-radius: 4px;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

button.close {
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}

.right-menu > .title .close {
  padding: 0 6px;
  flex-grow: 0;
  color: white;
  opacity: 1;
}

a[ng-click] {
  cursor: pointer;
}

[ngbDropdownMenu] {
  [role="menuitem"] {
    cursor: pointer;
  }
}

.public-page {
  .spaced-row {
    margin-top: 8px;
  }

  .spaced-row-x2 {
    margin-top: 16px;
  }

  .new-major-app-version {
    padding: 5px;
    background-color: #94414196;
  }

  .app-version-string-en,
  .app-version-string-dk {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.route-reset-password,
.route-login {
  background: url("../assets/images/bg.jpg");
  background-size: cover;
}

.route-login {
  #login-forgot-password-link {
    text-align: right;

    a {
      color: white;
    }
  }
}

.cards {
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  border-radius: 15px;
  color: white;
  padding: 40px 60px;
}

.flex-all {
  width: 100%;
}

.flex-all.vflex {
  height: 100%;
}

.vflex {
  flex-flow: column nowrap;
}

.flex-center {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
}

.hflex,
.vflex {
  display: flex;
  flex: 1 1 auto;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
}

.btn-toolbar > .btn,
.btn-toolbar > .btn-group,
.btn-toolbar > .input-group {
  margin-left: 5px;
}

.btn-group-right {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.index-notification-body {
  white-space: pre-line;
}

#index-loading-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  cursor: wait;
}

#index-loading-spinner {
  position: absolute;
  width: 100px;
  height: 100px;
  left: calc(50vw - 50px);
  top: calc(50vh - 50px);
}

#index-loading-spinner.disabled {
  display: none;
}

.flex-center {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
}

.center {
  display: inline-block;
}

label {
  cursor: pointer;
  font-weight: 100;

  input[type="checkbox"] {
    vertical-align: middle;
    margin-top: -1px;
  }
}

.debug {
  font-family: "Comic Sans MS", Papyrus, serif;
  font-weight: bolder;
  background: linear-gradient(
    to right,
    red,
    orange,
    yellow,
    green,
    cyan,
    blue,
    violet,
    red
  );
  fill: linear-gradient(
    to right,
    red,
    orange,
    yellow,
    green,
    cyan,
    blue,
    violet,
    red
  );
}

.btn.btn-busy {
  background-color: grey;
  background-image: url("../assets/images/spinner-gray.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  color: none;

  * {
    visibility: hidden;
  }
}

.btn.btn-alt-primary {
  background-color: hsla(28, 93%, 59%, 1);
  border-color: hsla(28, 70%, 60%, 1);
  color: #fff;
}

body,
.btn {
  font-size: 12px;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.65;
}

.btn-primary {
  background-color: #337ab7;
  border-color: #337ab7;

  --bs-btn-hover-bg: #286090;
  --bs-btn-hover-border-color: #204d74;
}

.btn-secondary {
  background-color: lightgray;
  color: black;
}

.btn-light {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  margin-left: 3px;
}

.btn-block {
  display: block;
  width: 100%;
}

#content {
  height: 100vh;
  /*This is overridden later by a script in index.html*/
}

.btn.btn-semi-block {
  display: block;
  width: calc(100% - 12px);
  margin: 6px 6px;
}

.form-control[disabled] {
  cursor: not-allowed;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eee;
  opacity: 1;
}

input[type="number"].no-spinner {
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="number"].no-spinner::-webkit-outer-spin-button,
input[type="number"].no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// 14_03_2023: This assurers, that the color of inputfields remain white when auto-filled with data.
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset;
}

select.ng-invalid,
input.ng-invalid {
  border: 1px solid rgba(217, 83, 79, 0.64);
}

.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"],
.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="radio"] {
  position: absolute;
  margin-top: 4px\9;
  margin-left: -17px;
}

.checkbox label,
.radio label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}

.checkbox,
.radio {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.modal-dialog {
  margin: 30px auto;
}

.modal-lg {
  --bs-modal-width: 900px;
}

.order-warnings,
.order-infos {
  font-weight: bold;
  text-align: right;
}
